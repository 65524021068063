<template>
  <el-dialog :title="params.name" append-to-body :modal="false" :visible.sync="isOpen" width="80%" >
    <en-table
      :loading="loading"
      :data="records"
      height="400"
    >
      <table-column v-for="item in headList" @openTable="handleOpenTable" :key="item.field" :data="item" :filter-sort-config="{}"></table-column>
    </en-table>
    <associated-detail-table ref="detailTable"></associated-detail-table>
  </el-dialog>
</template>

<script>
import { request } from "en-js";
import API from "@/api/businessModel/list";
import tableColumn from "@/components/en-table-extra/tableColumn";
import { BusinessFieldType } from "@/components/businessDetail/data/BusinessCommonHeader";
import Index from "@/views/businessModel/list/data/utils";

export default {
  name: "associatedDetailTable",
  components: {
    tableColumn
  },
  props: {},
  data() {
    return {
      BusinessFieldType,
      records: [],
      headList: [],
      loading: false,
      isOpen: false,
      params: {} // 其他参数
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.requestData();
      }
    }
  },
  methods: {
    @request(true, "loading")
    async requestData() {
      const { id, templateId, field } = this.params;
      const params = {
        id,
        templateId,
        field
      }; // 还是赋值, 免得都不知道接口参数是什么

      const res = await API.queryDetailTableHeadList(params);
      this.headList = res || [];
    },
       /**
   * 弹框 (明细表格 收付对象)
   * @param row
   * @param col
   */
    handleOpenTable({ type, row, col }) {
      const params = { ...col };
      params.id = row.id;
      params.version = row.version;
      params.templateId = row.templateId;
      console.log(type);
      const fieldValue = row[col.field];
      console.log(row[col.field]);
      if (!fieldValue || !fieldValue.length) {
        this.$message("无数据");
        return;
      }
      params.records = fieldValue;
      this.$refs.detailTable.open(params);
      // if (type === "detail") {
      //   this.$refs.detailTable.open(params);
      // } else if (type === "pay") {
      //   this.$refs.payObjectTable.open(params);
      // } else if (type === "process") {
      //   this.$refs.processTable.open(params);
      // } else if (type === "associatedData") {
      //   this.$refs.associatedDataTable.open(params);
      // }
    },
    // 明细表
    isDetailTable(col) {
      return col.relationType;
    },
    open(params) {
      this.params = params;
      this.records = params.records || [];
      this.isOpen = true;
    },
    cellText(row, col) {
      return Index.cellText(row, col);
    }
  }
};
</script>

<style scoped lang="scss">
  /deep/ .el-dialog {
    text-align: left;//被全局样式影响
  }
</style>
