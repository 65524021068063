<!--
 * @Descripttion: 关联数据表格
 * @Author: 彭博
 * @Date: 2020-08-27 11:54:44
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-04 11:37:09
-->
<template>
  <div class="base-table">
    <en-table ref="table" no-data-icon-size="small" :expand-config="{trigger:'default'}" :loading="loading" :optimization="tableOptimization"
      @row-click="handleRowClick" @filter-sort-change="handleFilterSortChange" :data="tableList" :row-id="rowId" :drag-config="{ dragRow: column.editable }"
      :head-end-config="headEndConfig" :page-config="pageConfig" max-height="600">
      <en-table-column v-if="canExpand" type="expand" title="展开" width="50">
        <template v-slot="{ row }">
          <sub-table :row-data="row" :column="column" :business-data="businessData"></sub-table>
        </template>
      </en-table-column>
      <en-table-column width="50" type="index" fixed="left"></en-table-column>
      <table-column v-for="item in showContent" @openTable="handleOpenTable" :attachment-sortable="true"
        is-home-page :key="item.field" :data="item" :filter-sort-config="filterSortConfig">
      </table-column>
      <template #hoverRow="{row, rowIndex}">
        <en-expand-buttons
          v-if="getButtonData(row).length"
          :data="getButtonData(row)"
          @button-click="
            handleButtonClick({
              row,
              rowIndex,
              btnData: arguments[1],
              btnIndex: arguments[0]
            })
          "
        >
        </en-expand-buttons>
      </template>
       <template #paginationLeft >
        <div class="field-statistics">
          <div v-for="(value, name) of fieldStatistics" :key="name" class="statistics-item">
            <strong>
              {{name == 2 ? "全部" : "本页"}} :
            </strong>
            <span class="content" v-for="item in value" :key="item.id">
              {{item.statisticsShow}}: {{item.value}}
            </span>
          </div>
        </div>
      </template>
    </en-table>
     <div class="field-statistics" v-if="showModel === 2">
      <div v-for="(value, name) of fieldStatistics" :key="name" class="statistics-item">
        <strong>
          {{name == 2 ? "全部" : "本页"}} :
        </strong>
        <span class="content" v-for="item in value" :key="item.id">
          {{item.statisticsShow}}: {{item.value}}
        </span>
      </div>
    </div>
    <detail-table ref="detailTable"></detail-table>
  </div>
</template>

<script>
import { groupBy } from "lodash";
import tableColumn from "@/components/en-table-extra/tableColumn";
import { BusinessFieldType, BusinessType, BusinessRelationType } from "@/components/businessDetail/data/BusinessCommonHeader";
import { newTabAddBusinessData } from "@/components/businessDetail/helper";
import { fmoney } from "@/tools/util";
import MixinRelation from "@/components/businessDetail/components/columnRendering/components/relevancyAdd/MixinRelation";
import detailTable from "./detailTable";
import SubTable from "./associatedSubTable";

export default {
  name: "BaseTable",
  componentName: "BaseTable",
  mixins: [MixinRelation],
  components: {
    tableColumn,
    detailTable,
    SubTable
  },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    tableList: {
      type: Array,
      default: () => []
    },
    pageConfig: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    showModel() {
      return this.column?.showModel;
    },
    fieldStatistics() {
      // showModel 1=>关联显示  2=>关联添加
      if (this.showModel === 1) {
        const fieldStatistics = this.pageConfig?.pageModel?.commParams.fieldStatistics || {};
        return groupBy(fieldStatistics, "statisticsScope");
      }
      const fieldStatistics = groupBy(this.column.fieldStatistics, "statisticsScope");
      const { tableList } = this;
      // statisticsRules: "1",//统计 {value:'1', text: '计数'}, {value:'2', text: '去重计数'},{value:'3', text: '求和'}, {value:'4', text: '求平均值'}
      // statisticsScope: "1",//1本页 2全部
      // setField: "m7709" 统计字段
      /* eslint-disable */
      for (const key in fieldStatistics) {
        fieldStatistics[key].forEach((item) => {
          const setFieldValues = tableList.map(obj => obj[item.setField])
          switch (item.statisticsRules) {
            case 1:
              item.value = setFieldValues.length;
              break;
            case 2:
              item.value = [...new Set(setFieldValues)].length;
              break;
            case 3:
              item.value = setFieldValues.reduce((acc, item) => {
                return acc + (Number(item) || 0)
              }, 0);
              item.value = fmoney(item.value);
              break;
            case 4:
              item.value = setFieldValues.reduce((acc, item) => {
                return acc + (Number(item) || 0)
              }, 0) / setFieldValues.length || 0;
              item.value = fmoney(item.value);
              break;
            default:
              break;
          }
        })
      }
      return fieldStatistics;
    },
    headEndConfig() {
      const { allowAddFlag, showModel, editable } = this.column;
      // if ((allowAddFlag === 0 && showModel === 1) || (editable && allowAddFlag === 0)) {
      //   console.log('新增按钮');
      //   return {
      //     type: "add",
      //     title: "手工新增",
      //     rowDataMethod: this.rowDataMethod
      //   };
      // }
      return {};
    },
    filterSortConfig() {
      if (this.column.showModel === 1 && Number(this.businessData.businessType) !== 1) {
        return { sortable: true, filterable: true };
      }
      return null;
    },
    canExpand() {
      const { selAreaType, showListStyle } = this.column;
      const selType = Number(selAreaType);
      return showListStyle === 2 && (selType !== "" && selType !== 1 && selType !== 4)
        && this.column.showContent.every((item) => item.areaShowField);
    }
  },
  data() {
    return {
      showContent: [],
      tableOptimization: {
        scrollX: {
          gt: 200
        }
      },
      rowId: "id",
      loading: false,
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: null,
        totalPages: null
      }
    };
  },
  methods: {
    // 筛选
    handleFilterSortChange({ filterData, sortData }) {
      this.$emit("handleFilterSortChange", { filterData, sortData });
    },
    addSuccess() {
      this.$emit("addSuccess");
    },
    recalculate() {
      this.$nextTick(() => {
        this.$refs.table?.recalculate();
      });
    },
    handleRowClick({ row, column: tableColumn }) {
      if (tableColumn.type === "expand") {
        return;
      }
      const { column } = this;
      let url;
      if (column.relationType === BusinessRelationType.form) { // 表单
        url = `/toBillDetails.do?mainId=${ row.id}`;
      } else if ([BusinessRelationType.businessObj, BusinessRelationType.attend].includes(column.relationType)) { // 业务对象 考勤
        return;
      } else if (column.relationType === BusinessRelationType.business) { // 业务建模
        if (column.relationData === "999999999") {
          url = `/toInvoiceDetail.do?invoiceId=${row.id}`;
        }
        newTabAddBusinessData({ templateId: row.templateId || column.templateId || column.relationData, id: row.mainId || row.id }, row.type);
      } else { // 基础数据
        newTabAddBusinessData({ objectTypeId: column.relationData, businessType: BusinessType.pureBasic, id: row.mainId || row.id }, 3);
      }
      console.log(url);
    },
    rowDataMethod() {
     this.checkCanAdd();
    },
    businessDetail_baseData(event) {
      if (event && event.key === "businessDetail_baseData") {
        console.log("基础数据新增回调");
        const res = JSON.parse(localStorage.getItem("businessDetail_baseData"));
        this.$emit("addBasicRow", res.id);
        // 新增基础数据 刷新页面 并移除监听
        window.removeEventListener("storage", this.businessDetail_baseData);
      }
    },
    businessDetail_businessData(event) {
      // FIXME:刷新页面 移除监听. 页面销毁也需要移除, 当前的弹窗实现方式没有销毁页面. 建议在最外层v-if下
      if (event && event.key === "businessDetail_businessData") {
        console.log("业务建模新增回调");
        const res = JSON.parse(localStorage.getItem("businessDetail_baseData"));
        if (!this.pageConfig) {
          this.$emit("addBasicRow", res.id);
        } else {
          this.$emit("addSuccess");
        }
        // 新增业务建模 刷新页面 并移除监听
        window.removeEventListener("storage", this.businessDetail_businessData);
      }
    },
    handleButtonClick({ rowIndex }) {
      if (rowIndex !== -1) {
        this.prevDelIndex = rowIndex;
      }
      this.tableList.splice(rowIndex === -1 ? this.prevDelIndex : rowIndex, 1);
      this.column.triggerInputLimitWatch = Math.random();
    },
    getButtonData() {
      if (!this.column.editable) return [];
      return [
        {
          icon: "iconshanchu-liebiao",
          name: "删除",
          iconColor: "#f76b6b"
        }
      ];
    },
    /**
   * 弹框 (明细表格 收付对象)
   * @param row
   * @param col
   */
    handleOpenTable({ type, row, col }) {
      const params = { ...col };
      params.id = row.id;
      params.version = row.version;
      params.templateId = row.templateId;
      console.log(type);
      const fieldValue = row[col.field];
      if (!fieldValue || !fieldValue.length) {
        this.$message("无数据");
        return;
      }
      params.records = fieldValue;
      this.$refs.detailTable.open(params);
      // if (type === "detail") {
      //   this.$refs.detailTable.open(params);
      // } else if (type === "pay") {
      //   this.$refs.payObjectTable.open(params);
      // } else if (type === "process") {
      //   this.$refs.processTable.open(params);
      // } else if (type === "associatedData") {
      //   this.$refs.associatedDataTable.open(params);
      // }
    },
    formatShowContent() {
      // b.fieldType == 11 || b.fieldType == 35||b.listIsShow==1
      // this.showContent = this.column.showContent.filter((field) => field.listIsShow === "0");
      // showListStyle 1平铺式 2抽屉式
      const {
        showListStyle, areaShowField, templateDataId, relationData
      } = this.column;
      this.showContent = this.column.showContent.filter((field) => field.listIsShow !== "1" && field.fieldType !== BusinessFieldType.detailParent && field.fieldType !== BusinessFieldType.subSet);
      if (showListStyle === 2) {
        this.showContent = this.showContent.filter((field) => field.areaShowField === areaShowField || !field.areaShowField);
      }
      this.showContent.forEach((item) => {
        item.templateId = templateDataId || relationData;
      });
    }
  },
  created() {
    this.formatShowContent();
    // 因为列表会有重复的,所以用index做rowId
    this.$eventHub.$on("confirmAdd", ({ field }) => {
      if (this.column.field === field) {
        this.rowId = "index";
      }
    });
  },
  destroyed() {
    this.$eventHub.$off("confirmAdd");
  }
};
</script>

<style lang="scss" scoped>
.base-table{
  /deep/.vxe-head-end-wrapper{
    background-color: #F2F5F8;
  }
  /deep/.vxe-header--row {
    font-size: 14px;
  }
  /deep/.vxe-header--row .is--firstCol{
    background-color: #F2F5F8;
    text-align: center;
  }
  /deep/.vxe-table.t--border .vxe-header--column{
    border-bottom: 1px solid #e6eaf0!important;
  }
  /deep/.vxe-table--header-wrapper{
    background-color: #F2F5F8;
}
  /deep/.vxe-hover-row-right-wrapper{
    width: 50px;
  }
}
/deep/.button{
  background-color: transparent !important;
}
/deep/.en-expand-buttons{
  background-color: transparent !important;
}
.field-statistics {
  display: flex;
  font-size: 12px;
  .statistics-item {
    margin-right: 20px;
    color: #606060;
    strong {
      margin-right: 8px;
    }
    span {
      display: inline-block;
      margin-right: 12px;
    }
  }
}
</style>
