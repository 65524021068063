var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.params.name,
        "append-to-body": "",
        modal: false,
        visible: _vm.isOpen,
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isOpen = $event
        },
      },
    },
    [
      _c(
        "en-table",
        { attrs: { loading: _vm.loading, data: _vm.records, height: "400" } },
        _vm._l(_vm.headList, function (item) {
          return _c("table-column", {
            key: item.field,
            attrs: { data: item, "filter-sort-config": {} },
            on: { openTable: _vm.handleOpenTable },
          })
        }),
        1
      ),
      _c("associated-detail-table", { ref: "detailTable" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }